// Active-menu

$('#main-nav ul li').addClass('has-subnav');

$('#main-nav ul li.has-subnav').mouseover(function() {
    $(this).addClass('active');
    $(".sub-nav").addClass("active");
    $('.overlay').addClass('menu-active');
    $('body').addClass('no-scroll');
    $(this).addClass("main-nav-second")
});

$('#main-nav ul li.has-subnav').mouseout(function() {
    $(this).removeClass('active');
    $('.overlay').removeClass('menu-active');
    $('body').removeClass('no-scroll');
});

$(".header-mainnav ul li.has-subnav").mouseover(function () {
    $(".overlay").addClass("active");
});

$(".header-mainnav").mouseout(function () {
    $(".overlay").removeClass("active");
});





// OffCanvas
$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $(".search-wrapper, .search-close, .bookmarks-toggle, .overlay").removeClass("search-active");
    $("body").toggleClass("no-scroll-off-canvas");
    $(".overlay-popup").toggleClass("no-scroll-overlay");
    var offCanvasHeight = $(".off-canvas-wrapper").height();
    $(".off-canvas-wrapper").css("height", offCanvasHeight);
});

$('.acc-menu > ul > li:has(ul)').addClass("has-subnav");
$('.acc-menu > ul > li > a').click(function() {
    $(this).closest('li').toggleClass('open') ;
});

//////// Header SEARCH


$(".search-toggle").click(function () {
    $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close, .overlay").toggleClass("search-active");
    $(".bookmarks-wrapper, .overlay").removeClass("bookmarks-active");
    $("body").toggleClass("no-scroll-search");
    $("body.no-scroll-bookmarks").removeClass("no-scroll-bookmarks");

    setTimeout("$('.search-wrapper > input:first-child').focus();", 500);
});

$(".search-close").click(function () {
    $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close, .overlay").removeClass("search-active");
    $("body").removeClass("no-scroll-search");
    $("body.no-scroll-bookmarks").removeClass("no-scroll-bookmarks");
});


// Press "ESC"
$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
        $(".bookmarks-wrapper").removeClass("bookmarks-active");
        $(".overlay").removeClass("active search-active bookmarks-active open-position-active");
        $(".hamburger").removeClass("is-active");
        $("#main-wrapper, .overlay").removeClass("off-canvas-active");
        $("#off-canvas").removeClass("active");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $('#lang-select > ul > li:first-child, #lang-select > ul > li >ul').removeClass("active");
        $(".prod-category-item-wrapper, .prod-item-content").removeClass("active");
        $(".filter-select-wrapper ul").removeClass("open");
        $(".popup").removeClass("active");
    }
});

$(".overlay").click(function () {
    $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
    $(".bookmarks-wrapper").removeClass("bookmarks-active");
    $(".overlay").removeClass("active search-active bookmarks-active open-position-active");
    $(".hamburger").removeClass("is-active");
    $("#main-wrapper, .overlay").removeClass("off-canvas-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
    $(".popup").removeClass("active");
});


/////// FOO Accordion

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }
    });
}

/*$(function () {
    var header = $("#header-main, #off-canvas");
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 20) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
    });
});*/

$(".foo-accordion").click(function(){
    $(this).toggleClass("active");
});



// Accordion Small
var acc = document.getElementsByClassName("accordion-small");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].onclick = function(){
        this.classList.toggle("active-acc");
        this.nextElementSibling.classList.toggle("show");
    }
};

// Slider - Unternehmen

$('.galleryslider').slick({
    dots: true,
    customPaging: function(slick,index) {
        var sliderItems = $( "div.unternehmen-item").not(".slick-cloned").toArray();
        return '<a>' + $(sliderItems[index]).data('location') + '</a>';
    },
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    arrows: false,
    adaptiveHeight: true
});


// Contant Slider

$('.contentslider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    fade: true,
    cssEase: 'linear'
});


// Slider Startseite

$('.slider-startseite').slick({
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 2000,
    cssEase: 'linear'
});


// Select Desktop
$( ".select-desktop li" ).click(function() {
    $(this).closest(".select-desktop").find("li:first-child").toggleClass( 'active' );
});


// Pop-Up

$(".open-position-item, .popup-toggle").click(function () {
    currentposition = $(document).scrollTop();
    $('body,html').animate({scrollTop: 0}, 500);

    popupid = $(this).data("open");
    $(".popup").each(function(){
        if($(this).data("id") == popupid){
            $(this).addClass("active");
            $(this).find(".popup-close").attr("data-currentpos", currentposition);
        }
    });

    $(".overlay").addClass("open-position-active");
});

$(".popup-close").click(function () {
    $(".overlay").removeClass("open-position-active");
    $('body,html').animate({scrollTop: $(".popup.active").find(".popup-close").data("currentpos")}, 500);
    $(".popup").removeClass("active");
});

$(".popup-form-cta-wrapper > button-popup").click(function () {
    $(".popup-form-wrapper").addClass("active");
});
